<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <vx-card class="mb-4" :title="$t(resources.Tracer.i18n) || resources.Tracer.name">
      <div class="vx-row">
        <vs-table :data="detail" class="vx-col w-full">
          <template slot="thead">
            <vs-th>
              {{
              $t(resources.Name.i18n) || resources.Name.name
              }}
            </vs-th>
            <vs-th>
              {{
              $t(resources.Email.i18n) || resources.Email.name
              }}
            </vs-th>
            <vs-th>
              {{
              $t(resources.Country.i18n) || resources.Country.name
              }}
            </vs-th>
            <vs-th>
              {{
              $t(resources.Mobile.i18n) || resources.Mobile.name
              }}
            </vs-th>
            <vs-th>
              {{
              $t(resources.Guide.i18n) || resources.Guide.name
              }}
            </vs-th>
            <vs-th>
              {{
              $t(resources.CreationDate.i18n) || resources.CreationDate.name
              }}
            </vs-th>
            <vs-th>
              {{
              $t(resources.Options.i18n) || resources.Options.name
              }}
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].customerName">{{ data[indextr].customerName }}</vs-td>
              <vs-td :data="data[indextr].email">
                {{
                data[indextr].email
                }}
              </vs-td>
              <vs-td :data="data[indextr].countryName">
                {{
                data[indextr].countryName
                }}
              </vs-td>
              <vs-td :data="data[indextr].phone">
                {{
                data[indextr].phone
                }}
              </vs-td>
              <vs-td :data="data[indextr].description">
                {{
                data[indextr].description
                }}
              </vs-td>
              <vs-td>
                {{
                data[indextr].CreateDateGuide
                }}
              </vs-td>
              <vs-td>
                <vs-button
                  color="danger"
                  type="flat"
                  icon-pack="feather"
                  icon="icon-trash-2"
                  size="large"
                  @click="disabled(data[indextr].tracerId)"
                ></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vx-card>

    <div id="ag-grid-demo">
      <vx-card>
        <!-- TABLE ACTION ROW -->
        <div class="flex flex-wrap justify-between items-center">
          <!-- ITEMS PER PAGE -->
          <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span
                  class="mr-2"
                >{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ detailList.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : detailList.length }} of {{ detailList.length }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                  <span>50</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                  <span>100</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                  <span>150</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
            <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button>
          </div>
        </div>
        <ag-grid-vue
          ref="agGridTable"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="detailList"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full text-right">
          <vs-button
            color="rgb(174,174,174)"
            type="filled"
            @click="$router.push('/tracer/index')"
          >{{$t(resources.Return.i18n) || resources.Return.name}}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import { AgGridVue } from "ag-grid-vue";
import axios from "axios";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      resources: resources,
      tracerId: 0,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      No: this.$i18n.t("No"),
      Yes: this.$i18n.t("Yes"),
      defaultColDef: {
        sortable: true,
        editable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t("Question"),
          field: "questionDescription",
          width: 520,
          filter: true
        },
        {
          headerName: this.$i18n.t("Answer"),
          field: "Respuesta",
          filter: true,
          width: 350,
          cellRenderer: function(event) {
            let object = "";
            switch (event.node.data.Respuesta) {
              case "N":
                object =
                  '<div class="con-vs-chip vs-chip-danger con-color" style="color: rgba(255, 255, 255, 0.9);"><span class= "text-chip vs-chip--text">' +
                  getAnswer(event.node.data.Respuesta) +
                  "</span></div>";
                break;
              case "S":
                object =
                  '<div class="con-vs-chip vs-chip-success con-color" style="color: rgba(255, 255, 255, 0.9);"><span class= "text-chip vs-chip--text">' +
                  getAnswer(event.node.data.Respuesta) +
                  "</span></div>";
                break;
              default:
                object = event.node.data.Respuesta;
                break;
            }
            return object;
          }
        },
        {
          headerName: this.$i18n.t("CreationDate"),
          field: "detailCreationDate",
          filter: true,
          width: 250
        }
      ],
      detailList: [],
      detail: []
    };
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("questionDescription", null);
      } else
        this.gridOptions.columnApi.setColumnPinned(
          "questionDescription",
          "left"
        );
    }
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 50;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  created: function() {
    this.tracerId = this.$route.params.tracerId;
    this.getDetailTracer();
  },
  methods: {
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },

    async getDetailTracer() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_NEOAPI}TracerDetail/getbyTracerId/${this.$i18n.locale}/${this.tracerId}`,
        headers: {
          "content-type": "application/json"
        }
      }).then(
        result => {
          if (result.data == 0) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.detailList = [];
            this.detail = [];
          } else {
            this.detailList = result.data;
            this.detail.push(result.data[0]);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async disabled(tracerId) {
      await axios.get(
        `${process.env.VUE_APP_NEOAPI}Tracer/disabled/${tracerId}`
      );
      this.$router.push({
        path: "/tracer/index"
      });
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  }
};

import VueI18n from "vue-i18n";
import i18nData from "../../i18n/i18nData.js";

const i18n = new VueI18n({
  locale: localStorage.getItem("language"), // set locale
  messages: i18nData // set locale messages
});
function getAnswer(data) {
  let value = i18n.t("Unclassified");
  switch (data) {
    case "S":
      value = i18n.t("Yes");
      break;
    case "N":
      value = i18n.t("No");
      break;
    default:
      break;
  }
  return value;
}
</script>
<style>
.con-vs-chip {
  max-height: 26px;
  width: 26px;
  font-size: 1.2rem;
  margin-top: 10px;
}
</style>