var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: {
            title:
              _vm.$t(_vm.resources.Tracer.i18n) || _vm.resources.Tracer.name
          }
        },
        [
          _c(
            "div",
            { staticClass: "vx-row" },
            [
              _c(
                "vs-table",
                {
                  staticClass: "vx-col w-full",
                  attrs: { data: _vm.detail },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].customerName } },
                                [_vm._v(_vm._s(data[indextr].customerName))]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].email } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].email) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].countryName } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].countryName) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].phone } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].phone) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                { attrs: { data: data[indextr].description } },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(data[indextr].description) +
                                      "\n            "
                                  )
                                ]
                              ),
                              _c("vs-td", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(data[indextr].CreateDateGuide) +
                                    "\n            "
                                )
                              ]),
                              _c(
                                "vs-td",
                                [
                                  _c("vs-button", {
                                    attrs: {
                                      color: "danger",
                                      type: "flat",
                                      "icon-pack": "feather",
                                      icon: "icon-trash-2",
                                      size: "large"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.disabled(
                                          data[indextr].tracerId
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ])
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Name.i18n) ||
                                _vm.resources.Name.name
                            ) +
                            "\n          "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Email.i18n) ||
                                _vm.resources.Email.name
                            ) +
                            "\n          "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Country.i18n) ||
                                _vm.resources.Country.name
                            ) +
                            "\n          "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Mobile.i18n) ||
                                _vm.resources.Mobile.name
                            ) +
                            "\n          "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Guide.i18n) ||
                                _vm.resources.Guide.name
                            ) +
                            "\n          "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.CreationDate.i18n) ||
                                _vm.resources.CreationDate.name
                            ) +
                            "\n          "
                        )
                      ]),
                      _c("vs-th", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(_vm.resources.Options.i18n) ||
                                _vm.resources.Options.name
                            ) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { attrs: { id: "ag-grid-demo" } },
        [
          _c(
            "vx-card",
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-between items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                    },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { "vs-trigger-click": "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                    " - " +
                                    _vm._s(
                                      _vm.detailList.length -
                                        _vm.currentPage *
                                          _vm.paginationPageSize >
                                        0
                                        ? _vm.currentPage *
                                            _vm.paginationPageSize
                                        : _vm.detailList.length
                                    ) +
                                    " of " +
                                    _vm._s(_vm.detailList.length)
                                )
                              ]),
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        20
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("20")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        50
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("50")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        100
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("100")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        150
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("150")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                    },
                    [
                      _c("vs-input", {
                        staticClass: "mb-4 md:mb-0 mr-4",
                        attrs: { placeholder: "Search..." },
                        on: { input: _vm.updateSearchQuery },
                        model: {
                          value: _vm.searchQuery,
                          callback: function($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery"
                        }
                      }),
                      _c(
                        "vs-button",
                        {
                          staticClass: "mb-4 md:mb-0",
                          on: {
                            click: function($event) {
                              return _vm.gridApi.exportDataAsCsv()
                            }
                          }
                        },
                        [_vm._v("Export as CSV")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  gridOptions: _vm.gridOptions,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.detailList,
                  rowSelection: "multiple",
                  colResizeDefault: "shift",
                  animateRows: true,
                  floatingFilter: true,
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true
                }
              }),
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full text-right" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "rgb(174,174,174)", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/tracer/index")
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Return.i18n) ||
                          _vm.resources.Return.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }